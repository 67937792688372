import React from "react";
import {
  SEO,
  PageLayout,
  Content,
  CallToActionBanner,
  OutboundLink,
  Image,
  Background,
  ContinuousScroll,
  PatternedImageRow,
  BasicCarousel,
  componentIterator,
  Review,
} from "@bluefin/components";
import { Embed, Grid, Header, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";
import "../style/index.css";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"home-page"}>
          <div className={"hero-images-container"}>
            <Embed
              url={
                "https://player.vimeo.com/video/819930068?background=1&h=5de5dbd32d"
              }
              autoplay={true}
              defaultActive={true}
              className={"home-hero-video"}
            />
          </div>
          <Grid
            columns={2}
            stackable={true}
            doubling={true}
            className={"welcome-to-grid"}
          >
            <Grid.Column className={"welcome-copy-column"}>
              <Content stackable={true} textAlign={"left"}>
                <Header as={"h1"} className={"welcome-to-header"}>
                  WELCOME TO {fishermanBusiness.name}
                </Header>
                <Header as={"h2"} className={"welcome-to-subheader"}>
                  DINE WITH US
                </Header>
                <Content.Markup
                  width={16}
                  isMarkdown={true}
                  content={fishermanBusiness.aboutMarkdown}
                />
              </Content>
              <Grid.Column width={16}>
                <CallToActionBanner stackable={true}>
                  <CallToActionBanner.CTA>
                    <Button
                      as={OutboundLink}
                      to={
                        "https://www.opentable.com/restref/client/?restref=1266316&lang=en-US&ot_source=Restaurant%20website&corrid=ae5da8a1-e52e-4d07-9540-d15e6ed9d45e"
                      }
                      primary={true}
                    >
                      Reserve Table
                    </Button>
                  </CallToActionBanner.CTA>
                </CallToActionBanner>
                <CallToActionBanner stackable={true}>
                  <CallToActionBanner.CTA>
                    <Button
                      as={OutboundLink}
                      to={
                        "https://direct.chownow.com/order/22838/locations/33302"
                      }
                      primary={true}
                    >
                      Order From Lennys Casita
                    </Button>
                  </CallToActionBanner.CTA>
                  <CallToActionBanner.CTA>
                    <Button
                      as={OutboundLink}
                      to={"https://www.lennysshabbatshuk.com/"}
                      primary={true}
                    >
                      Order For Shabbat Here
                    </Button>
                  </CallToActionBanner.CTA>
                </CallToActionBanner>
              </Grid.Column>
            </Grid.Column>
            <Grid.Column className={"welcome-image-column"}>
              <Image
                className={"image"}
                src={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "Content.Image",
                  componentIdentifier: "welcome",
                  numToSelect: 1,
                })}
              />
            </Grid.Column>
          </Grid>
          <div className={"image-gallery-container"}>
            <Background
              columns={1}
              stackable={true}
              className={"images-background"}
            >
              <Background.Pattern type={"CIRCLE"}>
                <ContinuousScroll
                  className={"patterned-images-container-scroll"}
                >
                  <PatternedImageRow
                    containerAs={ContinuousScroll.Item}
                    images={getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "ImageCollection",
                    })}
                  />
                </ContinuousScroll>
              </Background.Pattern>
            </Background>
          </div>
          <div className={"reviews-container"}>
            <BasicCarousel
              size={3}
              pageIndicatorIcon={null}
              mobile={{ arrowsPosition: "top" }}
              items={componentIterator({
                iterator: fishermanBusiness.reviews,
                component: <Review as={"horizontalItem"} charLimit={200} />,
                propMap: { author: "author", text: "text" },
              })}
            />
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      aboutMarkdown
      reviews {
        author
        text
        link
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          _id
        }
      }
    }
  }
`;
